import "bootstrap/dist/css/bootstrap.min.css";
import AES from "crypto-js/aes";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import "./App.css";

import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import StudentScore from "./components/all-scoring-list.component";
import CategoryList from "./components/category-list.component";
import CreateCategory from "./components/create-category.component";
import CreatePeriod from "./components/create-period.component";
import CreateStudent from "./components/create-student.component";
import CreateSubcategory from "./components/create-subcategory.component";
import EditCategory from "./components/edit-category.component";
import EditPeriod from "./components/edit-period.component";
import EditStudent from "./components/edit-student.component";
import EditSubcategory from "./components/edit-subcategory.component";
import Home from "./components/home.component";
import Login from "./components/login.component";
import MyScore from "./components/my-score.component";
import PeriodList from "./components/period-list.component";
import Profile from "./components/profile.component";
import Report from "./components/report.component";
import ScoreList from "./components/scoring-list.component";
import StudentList from "./components/student-list.component";
import SubcategoryList from "./components/subcategory-list.component";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  let decryptedRole = "";
  if (cookies["role"]) {
    decryptedRole = AES.decrypt(cookies["role"], "pmimagelangsecretkey")
      .toString()
      .slice(-1);
  }

  const [role, setRole] = useState(parseInt(decryptedRole));
  const [name, setName] = useState(cookies["name"]);
  const [token, setToken] = useState(cookies["token"]);

  function logout() {
    removeCookie("name");
    removeCookie("token");
    removeCookie("email");
    removeCookie("role");
    window.location.href = "/login";
  }

  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand>
                <img className="pmi-logo" alt="logo" src="/logo.png" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="offcanvasNavbar" />
              <Navbar.Offcanvas
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
                className="d-block d-md-none"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="offcanvasNavbarLabel">
                    PMI Kota Magelang
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav>
                    {token === undefined && (
                      <Nav>
                        <Link to={"/login"} className="nav-link">
                          Login
                        </Link>
                      </Nav>
                    )}
                    {token !== undefined && (
                      <Nav>
                        <Link to={"/score-list"} className="nav-link">
                          Beri Nilai
                        </Link>
                      </Nav>
                    )}
                    {token !== undefined && (
                      <Nav>
                        <Link to={"/my-score"} className="nav-link">
                          Nilai Saya
                        </Link>
                      </Nav>
                    )}
                    {role === 2 && (
                      <Nav>
                        <Link to={"/period-list"} className="nav-link">
                          Periode
                        </Link>
                      </Nav>
                    )}
                    {role === 2 && (
                      <Nav>
                        <Link to={"/report"} className="nav-link">
                          Laporan
                        </Link>
                      </Nav>
                    )}
                    {role === 2 && (
                      <Nav>
                        <Link to={"/student-list"} className="nav-link">
                          Pegawai
                        </Link>
                      </Nav>
                    )}

                    {role === 2 && (
                      <Nav>
                        <Link to={"/category-list"} className="nav-link">
                          Kategori
                        </Link>
                      </Nav>
                    )}
                    {role === 2 && (
                      <Nav>
                        <Link to={"/subcategory-list"} className="nav-link">
                          Sub Kategori
                        </Link>
                      </Nav>
                    )}
                    {token !== undefined && (
                      <Nav>
                        <Link to={"/profile"} className="nav-link">
                          Profile
                        </Link>
                      </Nav>
                    )}
                    {token !== undefined && (
                      <Nav>
                        <span onClick={logout}>
                          <Link to="#" className="nav-link">
                            Logout
                          </Link>
                        </span>
                      </Nav>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
              <Nav className="d-none d-lg-flex">
                {token === undefined && (
                  <Nav>
                    <Link to={"/login"} className="nav-link">
                      Login
                    </Link>
                  </Nav>
                )}
                {token !== undefined && (
                  <Nav>
                    <Link to={"/score-list"} className="nav-link">
                      Beri Nilai
                    </Link>
                  </Nav>
                )}
                {token !== undefined && (
                  <Nav>
                    <Link to={"/my-score"} className="nav-link">
                      Nilai Saya
                    </Link>
                  </Nav>
                )}
                {role === 2 && (
                  <Nav>
                    <Link to={"/period-list"} className="nav-link">
                      Periode
                    </Link>
                  </Nav>
                )}
                {role === 2 && (
                  <Nav>
                    <Link to={"/report"} className="nav-link">
                      Laporan
                    </Link>
                  </Nav>
                )}
                {role === 2 && (
                  <Nav>
                    <Link to={"/student-list"} className="nav-link">
                      Pegawai
                    </Link>
                  </Nav>
                )}

                {role === 2 && (
                  <Nav>
                    <Link to={"/category-list"} className="nav-link">
                      Kategori
                    </Link>
                  </Nav>
                )}
                {role === 2 && (
                  <Nav>
                    <Link to={"/subcategory-list"} className="nav-link">
                      Sub Kategori
                    </Link>
                  </Nav>
                )}
                {token !== undefined && (
                  <Nav>
                    <Link to={"/profile"} className="nav-link">
                      Profile
                    </Link>
                  </Nav>
                )}
                {token !== undefined && (
                  <Nav>
                    <span onClick={logout}>
                      <Link to="#" className="nav-link">
                        Logout
                      </Link>
                    </span>
                  </Nav>
                )}
              </Nav>
            </Container>
          </Navbar>
        </header>

        <Container>
          <Row>
            <Col md={12}>
              <div className="table-wrapper wrapper">
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path="/home"
                    component={(props) => <Home {...props} />}
                  />
                  <Route
                    exact
                    path="/login"
                    component={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path="/profile"
                    component={(props) => <Profile {...props} />}
                  />
                  <Route
                    exact
                    path="/create-student"
                    component={(props) => <CreateStudent {...props} />}
                  />
                  <Route
                    exact
                    path="/edit-student/:id"
                    component={(props) => <EditStudent {...props} />}
                  />
                  {role === 2 && (
                    <Route
                      exact
                      path="/sent-score/:id"
                      component={(props) => <StudentScore {...props} />}
                    />
                  )}
                  <Route
                    exact
                    path="/edit-category/:id"
                    component={(props) => <EditCategory {...props} />}
                  />
                  <Route
                    exact
                    path="/edit-subcategory/:id"
                    component={(props) => <EditSubcategory {...props} />}
                  />
                  <Route
                    exact
                    path="/student-list"
                    component={(props) => <StudentList {...props} />}
                  />
                  <Route
                    exact
                    path="/create-category"
                    component={(props) => <CreateCategory {...props} />}
                  />
                  <Route
                    exact
                    path="/category-list"
                    component={(props) => <CategoryList {...props} />}
                  />
                  <Route
                    exact
                    path="/period-list"
                    component={(props) => <PeriodList {...props} />}
                  />
                  {role === 2 && (
                    <Route
                      exact
                      path="/create-period"
                      component={(props) => <CreatePeriod {...props} />}
                    />
                  )}
                  <Route
                    exact
                    path="/edit-period/:id"
                    component={(props) => <EditPeriod {...props} />}
                  />
                  <Route
                    exact
                    path="/subcategory-list"
                    component={(props) => <SubcategoryList {...props} />}
                  />
                  {token !== undefined && (
                    <Route
                      exact
                      path="/score-list"
                      component={(props) => <ScoreList {...props} />}
                    />
                  )}
                  {token !== undefined && (
                    <Route
                      exact
                      path="/my-score"
                      component={(props) => <MyScore {...props} />}
                    />
                  )}
                  {role === 2 && (
                    <Route
                      exact
                      path="/report"
                      component={(props) => <Report {...props} />}
                    />
                  )}
                  <Route
                    exact
                    path="/create-subcategory"
                    component={(props) => <CreateSubcategory {...props} />}
                  />
                </Switch>
              </div>
            </Col>
          </Row>
        </Container>
      </Router>
    </div>
  );
}

export default App;
